import {
  Avatar,
  Flex,
  Icon,
  Td,
  Text,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

interface UserNameProps {
  given_name: string;
  family_name: string;
  email: string;
}

export default function UserName({
  given_name,
  family_name,
  email,
}: UserNameProps) {
  // Hooks
  const location = useLocation();

  const currentRoute = location.pathname;
  const isOnClientsPage = currentRoute.startsWith('/clients');

  // Theme
  const { colors } = useTheme();
  const color = useColorModeValue(colors.gray[700], colors.gray[200]);

  const hasName = given_name && family_name;

  return (
    <Td
      display={'flex'}
      alignItems="center"
      gap={2}
      w={'100%'}
      border={isOnClientsPage ? 'none' : ''}
      minH={'72px'}
    >
      <Avatar
        boxSize={[null, null, null, '30px', '32px', '36px']}
        size={[null, null, null, 'xs', 'sm', 'sm']}
        bgGradient={`linear(90deg, ${colors.secondary[300]} 5%, ${colors.secondary[500]} 80%)`}
        borderRadius={'50%'}
        color={'primary.100'}
        name={hasName ? `${given_name} ${family_name}` : undefined}
        icon={
          !hasName ? (
            <Icon
              as={AiOutlineUser}
              boxSize={[null, null, null, '16px', '18px', '20px']}
            />
          ) : (
            <></>
          )
        }
      />

      <Flex direction={'column'} gap={1} color={color}>
        {(given_name || family_name) && (
          <Text
            fontSize={[null, null, null, '14px', '14px', '16px']}
            textTransform={'capitalize'}
            fontWeight={500}
            lineHeight={1}
          >
            {given_name} {family_name}
          </Text>
        )}

        <Text
          fontSize={[null, null, null, '12px', '12px', '14px']}
          lineHeight={1}
          opacity={0.8}
        >
          {email}
        </Text>
      </Flex>
    </Td>
  );
}
