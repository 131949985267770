import { useMemo } from 'react';
import {
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';

import TeamsTable from 'components/teams/TeamsTable';
import TeamsRequestsTable from 'components/teams/TeamsRequestsTable';
import { maxHeight } from 'utils/responsive';
import Loading from 'components/ui/Loading';
import { errorHandler } from 'utils/helpers';
import { useTeamsAPI } from 'api/Users/useTeamsAPI';
import { useQuery } from '@tanstack/react-query';
import MainPanelError from 'components/ui/MainPanelError';
import { Team, Invites, Members } from 'models/Teams';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';

export default function TeamsView() {
  // Theme
  const headingColor = useColorModeValue('secondary.800', 'neutral.400');

  const { getTeams } = useTeamsAPI();

  const { isPending, error, data } = useQuery({
    queryKey: ['teams'],
    queryFn: getTeams,
    staleTime: 2 * 1000 * 60,
  });

  const teamsList: Team[] = useMemo(() => {
    let sorted = data?.sort(
      (a: Team, b: Team) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    sorted = sorted?.map((team: Team) => ({
      ...team,
      invites: team?.invites?.filter((invite: Invites) => !invite.accepted),
      owner: team?.members?.filter(
        (member: Members) => member.permission === 'admin'
      )[0].user,
    }));

    return sorted;
  }, [data]);

  const TeamsHeight = useBreakpointValue(maxHeight);

  const TeamsStyle = {
    height: 'fit-height',
    width: '100%',
    margin: '0 auto',
    padding: '6px',
    paddingRight: '12px',
    display: 'flex',
  };

  return (
    <CustomScrollBar style={TeamsStyle}>
      <Flex
        h={'100%'}
        overflowY="hidden"
        direction="column"
        mx="auto"
        gap={6}
        overflow={'hidden'}
        maxW={'1200px'}
        w={'95%'}
      >
        {/* heading */}
        <Heading
          fontSize={[null, null, null, '20px', '24px', '28px']}
          color={headingColor}
          fontWeight={'regular'}
          lineHeight={1}
        >
          Teams
        </Heading>

        {error ? (
          <MainPanelError errorMessage={errorHandler(error).message} />
        ) : isPending ? (
          <Box w={'100%'} h={`calc(${TeamsHeight} - 100px)`}>
            <Loading message="Fetching institutions..." />
          </Box>
        ) : (
          <TeamsTable teamsList={teamsList} />
        )}

        {!isPending && (
          <>
            {/* sub-heading */}
            <Heading
              fontSize={[null, null, null, '20px', '24px', '28px']}
              color={headingColor}
              fontWeight={'regular'}
              lineHeight={1}
            >
              Teams Requests
            </Heading>

            <TeamsRequestsTable />
          </>
        )}
      </Flex>
    </CustomScrollBar>
  );
}
