import { Outlet } from 'react-router-dom';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Highlight,
  Image,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { hexToRgba } from 'utils/helpers';

export default function AuthLayout() {
  const { colors } = useTheme();
  return (
    <Grid h="100vh" templateColumns="repeat(7, 1fr)" position={'relative'}>
      <GridItem colSpan={2}></GridItem>
      <GridItem colSpan={5} bg="secondary.700">
        <Box
          style={{
            backgroundImage: 'url(./man-walking.jpg)',
          }}
          bgSize="cover"
          bgPosition="top"
          bgRepeat="no-repeat"
          h="100vh"
          position="relative"
        >
          {/* Color overlay */}
          <Box
            bgGradient={`linear(to-br, ${hexToRgba(
              colors.secondary[700],
              0.1
            )}, ${hexToRgba(colors.secondary[700], 0.65)}, ${hexToRgba(
              colors.secondary[700],
              0.9
            )}, ${hexToRgba(colors.secondary[700], 1)})`}
            p="4"
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            color="neutral.200"
          >
            {/* Header Content */}
            <Box
              h="100vh"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              ml="38%"
            >
              {/* Logo */}
              <Flex mb={6}>
                <Center>
                  <Image
                    src="https://lakesai.s3.eu-central-1.amazonaws.com/logo/white.svg"
                    h="80px"
                    mr={4}
                    opacity={0.6}
                  />
                  <Heading color={'neutral.100'}>Lexai</Heading>
                </Center>
              </Flex>
              {/* Our mission  */}
              <Stack gap={4} maxW="450px">
                <Text fontSize={'xl'} color={'neutral.100'}>
                  <Highlight
                    query="dashboard"
                    styles={{
                      px: '2',
                      py: '1',
                      bg: 'neutral.300',
                      opacity: '0.6',
                      color: 'secondary.800',
                      rounded: 'full',
                      fontWeight: 'bold',
                    }}
                  >
                    Admin dashboard
                  </Highlight>
                </Text>
                <Text fontSize="sm" color={'neutral.100'}>
                  Invite, View or Revoke User Access
                </Text>
              </Stack>
            </Box>
          </Box>
        </Box>
      </GridItem>

      {/* Login */}
      <Box
        position="absolute"
        transform={'translateY(-50%) translateX(-50%)'}
        left="calc(2/7 * 100%)"
        top={'50%'}
      >
        <Outlet />
      </Box>
    </Grid>
  );
}
