import { useEffect, useState } from 'react';

import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  Box,
  Text,
  Button,
  InputRightElement,
} from '@chakra-ui/react';

import { maxHeight } from 'utils/responsive';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import TablePagination from 'components/clients/table/TablePagination';
import UserName from 'components/clients/table/UserName';
import { Team } from 'models/Teams';
import { format } from 'date-fns';
import TeamDetailsModal from './modals/TeamDetailsModal';
import ColHeader from 'components/clients/table/ColHeader';
import { _byDate } from 'components/clients/helpers';
import SearchUsers from 'components/clients/controls/SearchUsers';
import { SmallCloseIcon } from '@chakra-ui/icons';
import StatsTeams from './StatsTeams';

interface TeamsProps {
  teamsList: Team[];
}

export default function TeamsTable({ teamsList }: TeamsProps) {
  // Theme
  const bg = useColorModeValue('neutral.100', 'dark.700');
  const hoverBg = useColorModeValue('gray.100', 'dark.600');
  const infoColor = useColorModeValue('gray.600', 'gray.400');
  const grayColor = useColorModeValue('gray.600', 'gray.200');

  // States
  const [sortedTeams, setSortedTeams] = useState<Team[]>(teamsList);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isInvites, setIsInvites] = useState(false);
  const [sortColumn, setSortColumn] = useState('invited on');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [input, setInput] = useState('');

  // Pagination
  const ITEMS_PER_PAGE = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentItems = sortedTeams?.slice(itemOffset, endOffset);
  const pageCount = sortedTeams?.length
    ? Math.ceil(sortedTeams.length / ITEMS_PER_PAGE)
    : 0;
  // Pagination Handler
  const handlePageClick = (event: { selected: number }) => {
    if (sortedTeams?.length === 0) return;

    const newOffset = (event.selected * ITEMS_PER_PAGE) % sortedTeams?.length;
    setItemOffset(newOffset);
  };

  const openTeamModal = (user: Team, is_invites: boolean) => {
    setIsInvites(is_invites);
    setSelectedTeam(user);
    setIsTeamModalOpen(true);
  };

  const onClose = () => {
    setIsTeamModalOpen(false);
  };

  const handleChange = (value: string) => {
    setInput(value);
  };

  const clearSearch = () => {
    setInput('');
  };

  function handleSort(
    column: 'name' | 'type' | 'status' | 'invited on' | 'created at'
  ) {
    if (column === 'created at') column = 'invited on';
    setSortColumn(column);
    const newSortDirection =
      column === sortColumn && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newSortDirection);
    setSortColumn(column);

    let sorted: Team[] = [];
    if (column === 'invited on') sorted = [...sortedTeams].sort(_byDate);

    if (newSortDirection === 'desc' && column !== 'name') sorted.reverse();
    setSortedTeams(sorted);
  }

  useEffect(() => {
    // initially and if both filters are cleared
    let filteredUsers = teamsList;

    // Apply search filter based on input
    if (input) {
      // to apply search filter to all pages
      setItemOffset(0);

      const searchQuery = input.toLowerCase();
      filteredUsers = filteredUsers.filter(team => {
        const { name } = team;
        const email = team.owner.email;
        const l_name = `${name?.toLowerCase()}`;
        const l_email = email?.toLowerCase();

        return l_name?.includes(searchQuery) || l_email?.includes(searchQuery);
      });
    }

    setSortedTeams(filteredUsers);
  }, [input, teamsList]);

  // Styles
  const TeamsHeight = useBreakpointValue(maxHeight);
  const TeamsStyle = {
    height: 'fit-height',
    width: '100%',
    margin: '0 auto',
    padding: '6px',
    paddingRight: '12px',
    display: 'flex',
  };

  if (!teamsList || teamsList.length === 0) {
    return (
      <Box style={{ ...TeamsStyle }}>
        <Flex w={'full'} h={'full'} justify={'center'} align={'center'} gap={3}>
          <Text fontWeight={'600'} color={infoColor} fontSize={'14px'}>
            No teams found!
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      {/* filters & table  */}
      <Flex direction={'column'}>
        <Flex justify={'space-between'} align={'center'} px={2} py={4}>
          {/* Search and Filter features */}
          <Flex gap={4} align="center" borderRadius={'8px'} w={'100%'}>
            <SearchUsers
              rightComponent={
                !!input && (
                  <InputRightElement>
                    <SmallCloseIcon
                      cursor={'pointer'}
                      onClick={clearSearch}
                      w={4}
                      h={4}
                      color="gray.500"
                      bg={'gray.100'}
                      borderRadius={'50%'}
                    />
                  </InputRightElement>
                )
              }
              value={input}
              onChange={e => handleChange(e.target.value)}
            />
            <StatsTeams allTeams={teamsList} filteredTeams={sortedTeams} />
          </Flex>
        </Flex>
        {/* added this cond. to prevent initially displayed full-height scroll */}
        {TeamsHeight && (
          <Flex direction={'column'} gap={2}>
            <CustomScrollBar style={TeamsStyle}>
              <TableContainer boxShadow={'sm'} bg={bg} borderRadius={'8px'}>
                <Table variant="simple">
                  <Thead>
                    <Tr py={3}>
                      <Th color={'gray.500'} w={'13%'}>
                        Team Name
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Owner
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Members
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Invites
                      </Th>
                      <ColHeader
                        header="Created At"
                        onSort={handleSort}
                        w={'13%'}
                        highlighted={sortColumn === 'invited on'}
                      />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentItems && currentItems?.length > 0 ? (
                      currentItems?.map((team: Team, index: number) => (
                        <Tr
                          key={index}
                          _hover={{ bg: hoverBg }}
                          cursor={'pointer'}
                          onClick={() => openTeamModal(team, false)}
                        >
                          <Td
                            fontSize={[
                              null,
                              null,
                              null,
                              '14px',
                              '14px',
                              '16px',
                            ]}
                            textTransform={'capitalize'}
                            fontWeight={500}
                            lineHeight={1}
                          >
                            {team.name}
                          </Td>
                          <UserName
                            given_name={team?.owner?.given_name ?? ''}
                            family_name={team?.owner?.family_name ?? ''}
                            email={team?.owner?.email ?? ''}
                          />
                          <Td color={grayColor} fontSize="12px" minH={'72px'}>
                            {team.members?.length > 0 ? (
                              <Text>{team.members.length}</Text>
                            ) : (
                              <Text>No team members</Text>
                            )}
                          </Td>
                          <Td color={grayColor} fontSize="12px" minH={'72px'}>
                            {team.invites?.length > 0 ? (
                              <Button
                                size="xs"
                                bg={'transparent'}
                                borderWidth={'none'}
                                borderColor={'transparent'}
                                color={'blue.500'}
                                _hover={{
                                  borderColor: 'none',
                                  bg: 'transparent',
                                }}
                                onClick={event => {
                                  event.stopPropagation();
                                  openTeamModal(team, true);
                                }}
                              >
                                {team.invites.length}
                              </Button>
                            ) : (
                              <Text color={'gray.400'}>No pending invites</Text>
                            )}
                          </Td>
                          <Td color={grayColor} fontSize="12px" minH={'72px'}>
                            {format(new Date(team.created_at), 'MMMM d, yyyy')}
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td colSpan={6}>
                          <Flex
                            py={20}
                            align={'center'}
                            justify={'center'}
                            fontWeight={'500'}
                            color={'gray.400'}
                          >
                            No matched teams
                          </Flex>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </CustomScrollBar>
            <TablePagination
              onPageChange={handlePageClick}
              pageCount={pageCount}
            />
          </Flex>
        )}
      </Flex>

      <TeamDetailsModal
        isOpen={isTeamModalOpen}
        selectedTeam={selectedTeam}
        isInvites={isInvites}
        onClose={() => onClose()}
      />
    </>
  );
}
