import useAxiosPrivate from 'hooks/useAxiosPrivate';

export const useTeamsAPI = () => {
  const axiosPrivate = useAxiosPrivate();

  const getTeams = async () => {
    const response = await axiosPrivate.get(`/admin_dashboard/api/teams`);
    return response.data;
  };

  const getTeamsRequests = async () => {
    const response = await axiosPrivate.get(`/admin_dashboard/api/teams_requests`);
    return response.data;
  };

  return { getTeams, getTeamsRequests };
};
