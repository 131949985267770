import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Team } from 'models/Teams';
import TeamTable from './TeamTable';

interface TeamDetailsModalProps {
  isOpen: boolean;
  isInvites: boolean;
  onClose: () => void;
  selectedTeam: Team | null;
}

function TeamDetailsModal({
  isOpen,
  isInvites,
  onClose,
  selectedTeam,
}: TeamDetailsModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'xl'}
      isCentered
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
    >
      <ModalOverlay bg={'transparent'} />
      <ModalContent
        minW={{ base: '90%', lg: '750px' }}
        maxW={{ base: '90%', lg: '750px' }}
      >
        <ModalHeader
          color={'highlight.primary'}
          fontFamily={'Poppins, sans-serif'}
          borderBottomWidth={1}
          borderBottomColor={'gray.200'}
          fontSize={'16px'}
          fontWeight={'500'}
          textTransform={'capitalize'}
        >
          {selectedTeam?.name}{' '}
          {!isInvites ? (
            <Text as="span">Members</Text>
          ) : (
            <Text as="span">Pending Invites</Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TeamTable selectedTeam={selectedTeam} isInvites={isInvites} />
        </ModalBody>
        <ModalFooter>
          <Button size={'sm'} colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TeamDetailsModal;
