import { useState } from 'react';

import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  Box,
  Text,
} from '@chakra-ui/react';

import { maxHeight } from 'utils/responsive';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import TablePagination from 'components/clients/table/TablePagination';
import { Invites, Members, Team } from 'models/Teams';

interface TeamsProps {
  selectedTeam: Team | null;
  isInvites: boolean;
}

export default function TeamTable({ selectedTeam, isInvites }: TeamsProps) {
  // Theme
  const bg = useColorModeValue('neutral.100', 'dark.700');
  const hoverBg = useColorModeValue('gray.100', 'dark.600');
  const infoColor = useColorModeValue('gray.600', 'gray.400');
  const grayColor = useColorModeValue('gray.600', 'gray.200');

  // Pagination
  const ITEMS_PER_PAGE = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentMembers = selectedTeam?.members?.slice(itemOffset, endOffset);
  const invites = selectedTeam?.invites?.slice(itemOffset, endOffset);
  const pageCount = selectedTeam?.members?.length
    ? Math.ceil(selectedTeam?.members?.length / ITEMS_PER_PAGE)
    : 0;
  const _pageCount = selectedTeam?.invites?.length
    ? Math.ceil(selectedTeam?.invites?.length / ITEMS_PER_PAGE)
    : 0;
  // Pagination Handler
  const handlePageClick = (event: { selected: number }) => {
    if (selectedTeam?.members?.length === 0) return;

    const newOffset =
      (event.selected * ITEMS_PER_PAGE) % (selectedTeam?.members?.length ?? 0);
    setItemOffset(newOffset);
  };
  const _handlePageClick = (event: { selected: number }) => {
    if (selectedTeam?.invites?.length === 0) return;

    const newOffset =
      (event.selected * ITEMS_PER_PAGE) % (selectedTeam?.invites?.length ?? 0);
    setItemOffset(newOffset);
  };

  // Styles
  const TeamsHeight = useBreakpointValue(maxHeight);
  const TeamsStyle = {
    height: 'fit-height',
    width: '100%',
    margin: '0 auto',
    padding: '6px',
    paddingRight: '12px',
    display: 'flex',
  };

  if (!selectedTeam || selectedTeam?.members.length === 0) {
    return (
      <Box style={{ ...TeamsStyle }}>
        <Flex w={'full'} h={'full'} justify={'center'} align={'center'} gap={3}>
          <Text fontWeight={'600'} color={infoColor} fontSize={'14px'}>
            No members found!
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      <Flex direction={'column'}>
        {/* <Text color={'highlight.primary'} mb={4}>{selectedTeam.name}</Text> */}
        {/* added this cond. to prevent initially displayed full-height scroll */}
        {TeamsHeight && (
          <Flex direction={'column'} gap={2}>
            {!isInvites ? (
              <>
                <CustomScrollBar style={TeamsStyle}>
                  <TableContainer boxShadow={'sm'} bg={bg} borderRadius={'8px'}>
                    <Table variant="simple">
                      <Thead>
                        <Tr py={3}>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            First Name
                          </Th>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            Last Name
                          </Th>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            Email
                          </Th>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            Role
                          </Th>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            Permission
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentMembers && currentMembers?.length > 0 ? (
                          currentMembers?.map(
                            (member: Members, index: number) => (
                              <Tr
                                key={index}
                                _hover={{ bg: hoverBg }}
                                cursor={'pointer'}
                              >
                                <Td
                                  textTransform={'capitalize'}
                                  color={grayColor}
                                  fontSize="12px"
                                  minH={'72px'}
                                >
                                  {member?.user?.given_name}
                                </Td>
                                <Td
                                  textTransform={'capitalize'}
                                  color={grayColor}
                                  fontSize="12px"
                                  minH={'72px'}
                                >
                                  {member?.user?.family_name}
                                </Td>
                                <Td
                                  color={grayColor}
                                  fontSize="12px"
                                  minH={'72px'}
                                >
                                  {member?.user?.email}
                                </Td>
                                <Td
                                  color={grayColor}
                                  fontSize="12px"
                                  minH={'72px'}
                                >
                                  {member?.permission === 'admin' ? (
                                    <Text>Admin</Text>
                                  ) : (
                                    <Text>Member</Text>
                                  )}
                                </Td>
                                <Td
                                  color={grayColor}
                                  fontSize="12px"
                                  minH={'72px'}
                                >
                                  {member?.permission}
                                </Td>
                              </Tr>
                            )
                          )
                        ) : (
                          <Tr>
                            <Td colSpan={6}>
                              <Flex
                                py={20}
                                align={'center'}
                                justify={'center'}
                                fontWeight={'500'}
                                color={'gray.400'}
                              >
                                No matched members
                              </Flex>
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </CustomScrollBar>
                <TablePagination
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                />
              </>
            ) : (
              <>
                <CustomScrollBar style={TeamsStyle}>
                  <TableContainer boxShadow={'sm'} bg={bg} borderRadius={'8px'}>
                    <Table variant="simple">
                      <Thead>
                        <Tr py={3}>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            Email
                          </Th>
                          <Th bg="gray.100" color={'gray.500'} w={'13%'}>
                            Permission
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {invites && invites?.length > 0 ? (
                          invites?.map((invite: Invites, index: number) => (
                            <Tr
                              key={index}
                              _hover={{ bg: hoverBg }}
                              cursor={'pointer'}
                            >
                              <Td
                                color={grayColor}
                                fontSize="12px"
                                minH={'72px'}
                              >
                                {invite?.email}
                              </Td>
                              <Td
                                color={grayColor}
                                fontSize="12px"
                                minH={'72px'}
                              >
                                {invite?.permission}
                              </Td>
                            </Tr>
                          ))
                        ) : (
                          <Tr>
                            <Td colSpan={6}>
                              <Flex
                                py={20}
                                align={'center'}
                                justify={'center'}
                                fontWeight={'500'}
                                color={'gray.400'}
                              >
                                No matched invites
                              </Flex>
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </CustomScrollBar>
                <TablePagination
                  onPageChange={_handlePageClick}
                  pageCount={_pageCount}
                />
              </>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
}
