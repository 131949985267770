export const slugToName = (slug: string | undefined) => slug ? slug.replace(/-/g, ' ') : '';

export const tilesStyles = [
    { top: "0", left: "0", h: "10%", w: "22.5%", delay: 0 },
    { top: "0", left: "22.5%", h: "10%", w: "27.5%", delay: "-6s" },
    { top: "0", left: "50%", h: "10%", w: "27.5%", delay: "-4s" },
    { top: "0", left: "77.5%", h: "10%", w: "22.5%", delay: "-2s" },
    { top: "10%", left: "0", h: "22.5%", w: "22.5%", delay: "-4s" },
    { top: "10%", left: "22.5%", h: "22.5%", w: "27.5%", delay: "-2s" },
    { top: "10%", left: "50%", h: "22.5%", w: "27.5%", delay: 0 },
    { top: "10%", left: "77.50%", h: "22.5%", w: "22.5%", delay: "-4s" },
    { top: "32.5%", left: "50%", h: "22.5%", w: "27.5%", delay: "-6s" },
    { top: "32.5%", left: "77.5%", h: "22.5%", w: "22.5%", delay: "-2s" },
];

export const linesStyles = [
    { top: "10%", left: "22.5%" },
    { top: "32.5%", left: "50%" },
    { top: "55%", right: "22.5%" },
];

