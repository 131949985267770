import {
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  Heading,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import Overview from 'components/dashboard/Overview';
import History from 'components/dashboard/History';
import UsersChart from 'components/dashboard/UsersChart';
import { useMemo } from 'react';
import {
  cumulativeObjectsByDate,
  errorHandler,
  groupByDate,
} from 'utils/helpers';
import { maxHeight } from 'utils/responsive';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import OverviewInfo from 'components/dashboard/OverviewInfo';
import { User } from 'models/Users';
import { useUsersAPI } from 'api/Users/useUsersAPI';
import { useQuery } from '@tanstack/react-query';
import MainPanelError from 'components/ui/MainPanelError';

export interface ChartItemProps {
  [date: string]: number;
}

export default function HomePage() {
  // Theme
  const headingColor = useColorModeValue('secondary.800', 'neutral.400');
  const cardBg = useColorModeValue('neutral.100', 'dark.700');

  const { getUsers } = useUsersAPI();

  const { isPending, error, data } = useQuery({
    queryKey: ['users'],
    queryFn: getUsers,
    staleTime: 2 * 1000 * 60,
  });

  const usersList: User[] = useMemo(() => {
    let sorted = data?.sort(
      (a: User, b: User) =>
        new Date(b.invited_at).getTime() - new Date(a.invited_at).getTime()
    );
    sorted = sorted?.filter(
      (user: User) => user.role !== 'admin' && user.role !== 'manager'
    );

    return sorted;
  }, [data]);

  // chart 1: all users dataset
  const allUsersData = cumulativeObjectsByDate(usersList ?? []);

  // chart 2: active users dataset
  const activeUsers = usersList?.filter((user: User) => {
    if (!user?.last_login) return false;
    else {
      const lastLogin = new Date(user?.last_login);
      const currentDate: Date = new Date();

      // Calculate the difference in milliseconds
      const differenceInMilliseconds: number =
        currentDate.getTime() - lastLogin.getTime();

      // Convert milliseconds to weeks
      const millisecondsInWeek: number = 7 * 24 * 60 * 60 * 1000;
      const differenceInWeeks: number =
        differenceInMilliseconds / millisecondsInWeek;

      const isWithinOneWeek = differenceInWeeks < 1;

      return (
        (user.user_status === 'CONFIRMED' ||
          user.user_status === 'EXTERNAL_PROVIDER') &&
        user.enabled &&
        isWithinOneWeek
      );
    }
  });
  const activeUsersData = cumulativeObjectsByDate(activeUsers ?? []);

  // chart 3: new users dataset
  const newUsers = usersList?.filter((user: User) => user?.first_login);
  const groupedNewUsers = groupByDate(newUsers ?? []);
  const newUsersData: { [date: string]: number } = {};
  for (const date in groupedNewUsers) {
    if (Object.prototype.hasOwnProperty.call(groupedNewUsers, date)) {
      newUsersData[date] = groupedNewUsers[date].length;
    }
  }

  // Styles
  const OverviewHeight = useBreakpointValue(maxHeight);
  const OverviewStyle = {
    height: `calc(${OverviewHeight} - 86px)`,
    width: '100%',
    // maxWidth: maxOverviewWidth,
    margin: '0 auto',
    paddingRight: '12px',
    display: 'flex',
    // 'flex-direction': 'column',
  };

  return (
    <Flex
      h={'100%'}
      direction="column"
      mx="auto"
      gap={6}
      overflow={'hidden'}
      maxW={'1200px'}
      w={'95%'}
    >
      {/* heading + info */}
      <Flex gap={2} align={'flex-end'}>
        <Heading
          fontSize={[null, null, null, '20px', '24px', '28px']}
          color={headingColor}
          fontWeight={'regular'}
          lineHeight={1}
        >
          Overview
        </Heading>

        <OverviewInfo />
      </Flex>

      <CustomScrollBar style={OverviewStyle}>
        {/* Stats */}
        <Overview users={usersList ?? []} loading={isPending} />

        <Grid
          mt={'24px'}
          templateRows={[
            null,
            null,
            null,
            'repeat(2, 1fr)',
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
          ]}
          templateColumns={[
            null,
            null,
            null,
            'repeat(1, 1fr)',
            'repeat(5, 1fr)',
            'repeat(5, 1fr)',
          ]}
          gap={4}
          minH={[null, null, null, null, '420px', '440px']}
        >
          <GridItem colSpan={[null, null, null, 1, 3, 3]}>
            <Card bg={cardBg} h={'100%'}>
              <CardBody h={'100%'} flex={1}>
                {error ? (
                  <MainPanelError errorMessage={errorHandler(error).message} />
                ) : (
                  <UsersChart
                    allUsers={allUsersData}
                    activeUsers={activeUsersData}
                    newUsers={newUsersData}
                    preparingChart={isPending}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>

          <GridItem colSpan={[null, null, null, 1, 2, 2]}>
            <History />
          </GridItem>
        </Grid>
      </CustomScrollBar>
    </Flex>
  );
}
