import { Flex, Text } from '@chakra-ui/react';
import { Team } from 'models/Teams';

interface StatsTeamsProps {
  allTeams: Team[];
  filteredTeams: Team[];
}

function StatsTeams({ allTeams, filteredTeams }: StatsTeamsProps) {
  return (
    <Flex gap={4} align={'center'}>
      {/* Total nbr of users */}
      {!!allTeams?.length && (
        <Text
          fontWeight={'500'}
          fontSize={'14px'}
          lineHeight={1}
          color={'gray.500'}
        >
          Total:{' '}
          <Text as={'span'} fontWeight={'400'}>
            {allTeams?.length}
          </Text>
        </Text>
      )}

      {/* Results */}
      {!!filteredTeams?.length &&
        filteredTeams?.length !== allTeams?.length && (
          <Text
            fontWeight={'500'}
            fontSize={'14px'}
            lineHeight={1}
            color={'gray.500'}
          >
            Result{filteredTeams?.length !== 1 ? 's' : ''}:{' '}
            <Text as={'span'} fontWeight={'400'}>
              {filteredTeams?.length}
            </Text>
          </Text>
        )}
    </Flex>
  );
}

export default StatsTeams;
