import { useMemo, useState } from 'react';

import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Td,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  Box,
  Text,
} from '@chakra-ui/react';

import { useQuery } from '@tanstack/react-query';

import { maxHeight } from 'utils/responsive';
import { CustomScrollBar } from 'components/ui/CustomScrollBar';
import TablePagination from 'components/clients/table/TablePagination';
import { TeamsRequests } from 'models/Teams';
import { useTeamsAPI } from 'api/Users/useTeamsAPI';
import UserName from 'components/clients/table/UserName';
import { getName } from 'utils/helpers';
import { format } from 'date-fns';

export default function TeamsRequestsTable() {
  const { getTeamsRequests } = useTeamsAPI();

  const { data } = useQuery({
    queryKey: ['teams-requests'],
    queryFn: getTeamsRequests,
    staleTime: 2 * 1000 * 60,
  });

  const requestsList: TeamsRequests[] = useMemo(() => {
    let sorted = data?.sort(
      (a: TeamsRequests, b: TeamsRequests) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    return sorted;
  }, [data]);

  // Theme
  const bg = useColorModeValue('neutral.100', 'dark.700');
  const hoverBg = useColorModeValue('gray.100', 'dark.600');
  const infoColor = useColorModeValue('gray.600', 'gray.400');
  const grayColor = useColorModeValue('gray.600', 'gray.200');

  // Pagination
  const ITEMS_PER_PAGE = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + ITEMS_PER_PAGE;
  const currentRequests = requestsList?.slice(itemOffset, endOffset);
  const pageCount = requestsList?.length
    ? Math.ceil(requestsList.length / ITEMS_PER_PAGE)
    : 0;
  // Pagination Handler
  const handlePageClick = (event: { selected: number }) => {
    if (requestsList?.length === 0) return;

    const newOffset = (event.selected * ITEMS_PER_PAGE) % requestsList?.length;
    setItemOffset(newOffset);
  };

  // Styles
  const TeamsHeight = useBreakpointValue(maxHeight);
  const TeamsStyle = {
    height: 'fit-height',
    width: '100%',
    margin: '0 auto',
    padding: '6px',
    paddingRight: '12px',
    display: 'flex',
  };

  if (!requestsList || requestsList.length === 0) {
    return (
      <Box style={{ ...TeamsStyle }}>
        <Flex w={'full'} h={'full'} justify={'center'} align={'center'} gap={3}>
          <Text fontWeight={'600'} color={infoColor} fontSize={'14px'}>
            No teams requests found!
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      {/* filters & table  */}
      <Flex direction={'column'} mb={8}>
        {/* added this cond. to prevent initially displayed full-height scroll */}
        {TeamsHeight && (
          <Flex direction={'column'} gap={2}>
            <CustomScrollBar style={TeamsStyle}>
              <TableContainer boxShadow={'sm'} bg={bg} borderRadius={'8px'}>
                <Table variant="simple">
                  <Thead>
                    <Tr py={3}>
                      <Th color={'gray.500'} w={'13%'}>
                        Institution
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Owner
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Members
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Is LMIC?
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Status
                      </Th>
                      <Th color={'gray.500'} w={'13%'}>
                        Submitted On
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentRequests && currentRequests?.length > 0 ? (
                      currentRequests?.map(
                        (team: TeamsRequests, index: number) => (
                          <Tr
                            key={index}
                            _hover={{ bg: hoverBg }}
                            cursor={'pointer'}
                          >
                            <Td
                              fontSize={[
                                null,
                                null,
                                null,
                                '14px',
                                '14px',
                                '16px',
                              ]}
                              textTransform={'capitalize'}
                              fontWeight={500}
                              lineHeight={1}
                            >
                              {team.name}
                            </Td>
                            <UserName
                              given_name={
                                getName('first', team.fullname.trim()) ?? ''
                              }
                              family_name={
                                getName('last', team.fullname.trim()) ?? ''
                              }
                              email={team?.email ?? ''}
                            />
                            <Td color={grayColor} fontSize="12px" minH={'72px'}>
                              {team.request_status.members}
                            </Td>
                            <Td color={grayColor} fontSize="12px" minH={'72px'}>
                              {team?.lmic && (
                                <>
                                  <Text fontWeight={'bold'}>Yes</Text>
                                  <Text>{team.lmic}</Text>
                                </>
                              )}
                            </Td>
                            <Td
                              textTransform={'capitalize'}
                              color={grayColor}
                              fontSize="12px"
                              minH={'72px'}
                            >
                              {team.request_status.status}
                            </Td>
                            <Td color={grayColor} fontSize="12px" minH={'72px'}>
                              {format(
                                new Date(team.created_at),
                                'MMMM d, yyyy'
                              )}
                            </Td>
                          </Tr>
                        )
                      )
                    ) : (
                      <Tr>
                        <Td colSpan={6}>
                          <Flex
                            py={20}
                            align={'center'}
                            justify={'center'}
                            fontWeight={'500'}
                            color={'gray.400'}
                          >
                            No matched teams requests
                          </Flex>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </CustomScrollBar>
            <TablePagination
              onPageChange={handlePageClick}
              pageCount={pageCount}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
}
