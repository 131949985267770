import { AxiosError } from 'axios';
import { User } from 'models/Users';
import { ChartItemProps } from 'views/home';
import { format, isToday, isYesterday, isSameYear } from 'date-fns';

interface FreqObj {
  [key: string]: number;
}

interface StylesObject {
  [key: string]: string;
}

// count item occurence
function FrequencyObject(arr: string[]) {
  return arr.reduce((acc: FreqObj, item: string) => {
    acc[item] = (acc[item] || 0) + 1;
    return acc;
  }, {});
}

// echarts-format data
export function transformDataForChart(arr: string[] | []) {
  return Object.keys(FrequencyObject(arr)).map(key => ({
    name: key,
    value: +FrequencyObject(arr)[key],
  }));
}

export function stylesToString(stylesObj: StylesObject) {
  let styleString = '';

  for (const property in stylesObj) {
    if (stylesObj.hasOwnProperty(property)) {
      styleString += `${property}: ${stylesObj[property]}; `;
    }
  }

  return styleString.trim();
}

export function savedAt() {
  const now = new Date();
  return now.toISOString().split('T')[0];
}

export function hexToRgba(hexCode: string, opacity = 1): string {
  // fallback color
  if (!hexCode) return 'black';

  // Remove the # symbol from the hex code
  hexCode = hexCode.replace('#', '');

  // If the hex code is short, expand it to the full 6-digit format
  if (hexCode.length === 3) {
    hexCode = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
  }

  // Parse the hex code into RGB values
  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);

  // Calculate the opacity value
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function groupByDate(objects: User[]): {
  [date: string]: User[];
} {
  const groupedObjects: { [date: string]: User[] } = {};

  objects?.forEach((obj: User) => {
    const cleanedDate = obj.invited_at.split('T')[0]; // Extracting yyyy-mm-dd part
    if (!groupedObjects[cleanedDate]) {
      groupedObjects[cleanedDate] = [];
    }
    groupedObjects[cleanedDate].push(obj);
  });

  return groupedObjects;
}

export function cumulativeObjectsByDate(objects: User[]): ChartItemProps {
  const groupedObjects = groupByDate(objects);

  let cumulativeCount = 0;
  const cumulativeCountsByDate: ChartItemProps = {};

  // Iterate over each date and accumulate the count
  Object.keys(groupedObjects)
    .sort()
    .forEach(date => {
      cumulativeCount += groupedObjects[date].length;
      cumulativeCountsByDate[date] = cumulativeCount;
    });

  return cumulativeCountsByDate as ChartItemProps;
}

export function getDayOneMonthAgo(): string {
  const currentDate: Date = new Date();
  currentDate.setDate(currentDate.getDate() - 30);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
  };
  return currentDate.toLocaleDateString('en-US', options);
}

export const errorHandler = (Error: any) => {
  let errorMessage = null;

  if (Error instanceof AxiosError) {
    // Handle AxiosError specifically
    if (Error.response && Error.response.data) {
      // NOTE: to delete this if-else check after implementing it on the server
      if (Error.response.data.message?.toLowerCase() === 'session not found')
        errorMessage = 'Conversation not found';
      else errorMessage = Error.response.data.message;
      // errorMessage = Error.response.data.message; // NOTE: to unmute
    } else {
      errorMessage = Error.message;
    }
  } else {
    // Handle plain Error
    errorMessage = Error?.message;
  }
  return { message: errorMessage ?? 'An unknown error occurred' };
};

export const formatDate = (date: Date) => {
  const now = new Date();
  if (isToday(date)) {
    return `Today, ${format(date, 'HH:mm')}`;
  } else if (isYesterday(date)) {
    return `Yesterday, ${format(date, 'HH:mm')}`;
  } else if (isSameYear(date, now)) {
    return format(date, 'MMMM dd, HH:mm');
  } else {
    return format(date, 'MMM dd, yyyy HH:mm');
  }
};

// Get firstname and lastname from fullname
export const getName = (name: string, fullname: string) => {
  // name is either firstname or lastname
  let arr = fullname?.split(' ');

  if (name === 'first') {
    return titleCase(arr[0]);
  } else {
    if (arr.length === 3) {
      // Combine last name and surname
      return titleCase(arr[1] + ' ' + titleCase(arr[2]));
    } else {
      return titleCase(arr[1]);
    }
  }
};

export const titleCase = (str: string) => {
  return str?.toLowerCase()?.replace(/\b\S/g, function (t) {
    return t?.toUpperCase();
  });
};
